@import 'config';

.billing-information {
    margin-bottom: 10px;
    .billing-address {
        display: flex;
        flex-direction: column;
        @media #{$medium-up} {
            flex-direction: row;
            flex-wrap: wrap;
        }
        .country {
            @media #{$medium-up} {
                margin-right: 50%;
            }
        }
        .title {
            margin-top: 2em !important;
        }
        .credit-card-title {
            margin-top: 0 !important;
        }
        .subtitle {
            color: $color-text-table-content;
        }
        .mat-mdc-form-field {
            @media #{$medium-up} {
                width: 48%;
                padding: 0em 1em;
            }
        }
        .mat-mdc-slide-toggle {
            @media #{$medium-up} {
                margin-left: 2em;
            }
            width: 100%;
            margin-bottom: 1em;
        }
        /*  TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
        .mat-slide-toggle-content {
            color: $color-text-secondary;
            letter-spacing: 0.46px;
            font-weight: 600;
            font-size: 14px;
        }
        .twin-fields {
            width: 100%;
            @media #{$medium-up} {
                width: 50%;
            }
            margin-right: -1em;
            .mat-mdc-form-field{
                width: 100%;
                @media #{$medium-up} {
                    width: 48%;
                }
            }
        }
        .business-address {
            margin: 0.2em 0 0.5em 2em;
            font-size: 18px;
            line-height: 25px;
            color: $color-text-table-content;
        }
        /*  TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
        .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
            background-color: #008464;
        }
        /*  TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
        .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
            background-color: #9ACDC1;
        }
    }
    .method-types {
        display: flex;
        margin: 1em;
        flex-direction: column;
        @media #{$medium-up} {
            flex-direction: row;
        }
        .method {
            flex-basis: 98%;
            @media #{$medium-up} {
                flex-basis: 48%;
            }
            p {
                font-size: 13px;
                color: $color-text-secondary;
                padding-left: 2rem;
                line-height: 20px;
            }
        }
    }
  /*  .credit-card-details {
        display: flex;
        flex-direction: column;
        margin: 1em;
        @media #{$medium-up} {
            flex-direction: row;
        }
        .card-no {
            flex: 1;
        }
        .twin-fields {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;            
            @media #{$medium-up} {
                flex-direction: row;
                margin-left: 1.5em;
                align-items: center;
            }
            img {
                width: 40px;
                height: 30px;
            }
        }
        .card-no,
        .card-date,
        .card-cvv,
        .cvv-img {
            display: flex;
            flex-direction: column;
        }
        .card-no label,
        .card-date label,
        .card-cvv label {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.36px;
            color: $color-text-secondary;
        }
        .cvv-img label {
            font-size: 14px;
            color: #8b8b8b;
            letter-spacing: 0.46px;
        }
    } */
    /*  TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-label-content {
        font-size: 14px;
        color: $color-text-table-content;
        letter-spacing: 0.46px;
        font-weight: 600;
    }
    .address{
        display: flex;
        flex-wrap: wrap;
    }

    .billing-method {
        @include flex-column();
    }
}
