@import 'config';

.red-asterik {
    color: $ups-warning-red;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    overflow: visible;
}

.insurance-shipments {
    label {
        font-size: 16px;
        width: 100%;
        color: $ups-gray-1;
    }

    label.mdc-label {
        color: $ups-gray-1;
        font-size: 14px;
        font-style: normal;
    }

    span[matsuffix] {
        color: $ups-gray-2;
    }

    .second {
        @media #{$medium-up} {
            margin-left: 17px;
        }
    }

    .shipment-information {
        margin: 20px 0 1em 1em;
        color: $color-text-table-content;
        display: flex;
        flex-direction: column;

        @media #{$medium-up} {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .mat-mdc-form-field {
            @media #{$medium-up} {
                width: 48%;
                padding: 0em 1em;

                &:nth-child(1),
                &:nth-child(2) {
                    padding-top: 1em;
                }
            }
        }

        .mat-form-field-dropdown {
            @media #{$medium-up} {
                width: 52%;
                padding: 0em 1em;

                &:nth-child(1),
                &:nth-child(2) {
                    padding-top: 1em;
                }
            }
        }

        .grid {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
    
            .value-containers {
                align-items: center;
    
                .value-wrapper {
                    display: flex;
                    position: relative;
                }

                .maxmimum-value-per-package-dropdown {
                    @media #{$medium-up} {
                        margin-left: 35px;
                    }
                }

                .total-annual-packages-insured {
                    margin-right: 30px;
                }
    
                .header {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 0;
                    width: 100%;
                    color: $ups-gray-1;
                    font-weight: 400;
                    max-width: 145px;

                    @media #{$medium-up} {
                        width: 140px;
                    }
                }

                .total-annual-packages-insured-mat-form-field {
                    @media #{$medium-up} {
                        width: 52%
                    }
                }

    
                mat-error {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: -0.5rem;
                    left: 12rem;
                    width: 100%;
                    color: $ups-warning-red;
                    font-size: 12px;
    
                    @media #{$medium-up} {
                        bottom: -1rem;
                        left: 10rem;
                    }
                }
            }
        }

        .shipping-frequency-form-field {
            width: 60%;
        }
    }

    .geographical-limits-of-coverage {
        @extend .shipment-information;

        .grid {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
    
            .value-containers {
                align-items: center;
    
                .value-wrapper {
                    display: flex;
                    position: relative;
                }

                .header {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 0;
                    width: 100%;
                    min-width: 140px;
                    color: $ups-gray-1;
                    font-weight: 400;

                    @media #{$medium-up} {
                        width: 140px;
                        
                        min-width: auto;
                    }
                }

                mat-error {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: -0.5rem;
                    left: 10rem;
                    width: 100%;
                    color: $ups-warning-red;
                    font-size: 12px;
    
                    @media #{$medium-up} {
                        bottom: -1rem;
                        left: 10rem;
                    }
                }
            }
        }

        .geographical-sum-error {
            margin-top: 10px;
            margin-bottom: 0;
            color: $ups-warning-red;
            font-size: 12px;
        }
    }

    .service-level-coverage {
        @extend .shipment-information;

        .grid {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
    
            .value-containers {
                align-items: center;
    
                .value-wrapper {
                    display: flex;
                    position: relative;
                }
    
                .header {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 0;
                    width: 100%;
                    min-width: 140px;
                    color: $ups-gray-1;
                    font-weight: 400;

                    @media #{$medium-up} {
                        width: 140px;
                        min-width: auto;
                    }
                }
    
                mat-error {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: -0.5rem;
                    left: 10rem;
                    width: 100%;
                    color: $ups-warning-red;
                    font-size: 12px;
    
                    @media #{$medium-up} {
                        bottom: -1rem;
                        left: 10rem;
                    }
                }
            }
        }

        .service-level-sum-error {
            margin-top: 10px;
            margin-bottom: 0;
            color: $ups-warning-red;
            font-size: 12px;
        }
    }

    






    .claim-history {
        width: 96%;

        .title {
            display: block;
            margin-bottom: 2em;
        }

        .subtitle {
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-label {
                font-weight: 600;
            }
        }

        .claim-detail {
            .mat-mdc-form-field {
                @media #{$medium-up} {
                    &:nth-child(1) {
                        flex-basis: 170px;
                    }

                    &:nth-child(2) {
                        flex-basis: 236px;
                    }

                    &:nth-child(3) {
                        flex-basis: 201px;
                    }

                    &:nth-child(4) {
                        flex-basis: 266px;
                    }
                }
            }
        }

        .checkbox-container {
            margin: 0.5em 0 1em 1em;
            color: $ups-gray-1;
            font-weight: 400;
        }
    }

    .business-references {
        @include flex-column();
        width: 96%;

        .business-reference {
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-infix {
                width: 0;
            }

            .mat-mdc-form-field {
                @media #{$medium-up} {
                    &:nth-child(1) {
                        flex-basis: 314px;
                    }

                    &:nth-child(2) {
                        flex-basis: 257px;
                    }

                    &:nth-child(3) {
                        flex-basis: 155px;
                    }

                    &:nth-child(4) {
                        flex-basis: 180px;
                    }
                }
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-subscript-wrapper {
                    word-break: break-all;
                    position: relative;
                }
            }
        }
    }

    .claim-detail,
    .business-reference {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media #{$medium-up} {
            flex-direction: row;
            margin: 0em 1em;
            justify-content: space-between;
        }
    }

    .terms {
        border: 1px solid $color-background-darker;
        padding: 0em 2em;
        margin-bottom: 2em;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 20px;
            color: $color-text-table-content;
            letter-spacing: 0.66px;
            font-weight: 600;
            margin-bottom: 0;
        }

        p:first-child {
            color: $ups-black-4;
        }

        p:last-child {
            color: $color-text-secondary;
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-layout .mat-checkbox-label {
                display: block;
            }
        }

        p {
            font-size: 14px;
        }

        a {
            font-weight: 600;
        }

        .signature {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .mat-mdc-form-field {
                flex-grow: 1;
                margin-right: 1em;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

    }

    /* .terms1{
        width: 98%;
        font-size: 14px;
        line-height: 20px;
        color: $color-text-secondary;
        letter-spacing: 0.46px;
        border: 1px solid #ededed;
        padding: 1.6em;
        margin-bottom: 1em;

        a {
            font-weight: 600;
        } 
    }*/

}
