@import '../../../assets/css/config.scss';

* {
    font-family: Roboto;
}

.red-asterik {
    color: $ups-warning-red;
}

.empty-form-field {
    @media #{$medium-up} {
        width: 48%;
        padding: 0em 1em;
    }
}

.contact-preference {
    .contact-wrapper {
        margin-top: 10px;
    }
}

.select-service-container {
    margin-top: 20px;
    display: block;
    justify-content: space-between;
    width: 94%;

    @media #{$medium-up} {
        display: flex;
    }
    .select-service-wrapper {
        border-radius: 5px;
        border: 1px solid $ups-gray-2;
        padding: 20px 22px;

        @media #{$medium-up} {
            width: 48%;
        }

        label.mdc-label {
            color: $ups-gray-1;
            font-size: 16px;
            font-weight: 500;
            margin-left: 10px; 
        }

        span {
            color: $ups-gray-1;
            font-size: 0.875rem;
            font-weight: 400;
            display: block;
            margin-left: 43px;
        }
    }

    .selected-service-wrapper {
        border: 2px solid $ups-blue-ppi;
        background: $ups-blue-ppi-lighter;
    }
    
}

