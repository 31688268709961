@import '../../../assets/css/config.scss';

.business-information {
    margin-bottom: 10px;

    .red-asterik {
        color: $ups-warning-red;
    }

    .company-info {
        margin: 1em 1em;
        display: flex;
        flex-direction: column;

        @media #{$medium-up} {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .mat-mdc-form-field {
            @media #{$medium-up} {
                width: 49%;
                padding: 0em 1em;
            }
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-hint-wrapper .mat-mdc-form-field-hint {
            color: #9b9b9b;
            font-size: 14px;
        }

        .business-location {
            display: flex;
            flex-direction: column;

            @media #{$medium-up} {
                width: 49%;
                //margin-right: -.7em;

                >label {
                    padding-left: 1em;
                }
            }

            >label {
                font-size: 13px;
                letter-spacing: 0.46px;
                color: $color-text-table-content;
                font-weight: 600;
                line-height: 2.5;
                padding-left: 2rem;
            }

            .mat-mdc-form-field {
                width: 100%;
            }
        }

        .which-one {
            width: 100%;
            justify-content: flex-end;
            display: flex;
            margin-right: 1.5em;

            .mat-mdc-form-field {
                width: 100%;

                @media #{$medium-up} {
                    width: 50%;
                }
            }
        }
    }

    .business-scale {
        margin: 1em 1em;
        display: flex;
        flex-direction: column;

        @media #{$medium-up} {
            flex-direction: row;
            margin: 1em 3.5em 1em 2em;
            justify-content: space-between;
        }

        @media only screen and (min-width:1650px) {
            margin: 1em 4em 1em 2em;
        }

        .business-details {
            display: flex;
            flex-direction: row;
            color: $color-text-table-content;
            align-items: baseline;

            @media #{$medium-up} {
                // margin-right: -2em;
                width: 23%;
            }

            .key {
                width: 50%;
                font-size: 14px;
                font-weight: 600;

                @media #{$small-only} {
                    margin-right: 1em;
                }
            }

            .value {
                width: 50%;
                margin-top: -0.5em;

                .mat-mdc-form-field {
                    display: block;
                    //text-align: center;
                }

                @media #{$medium-up} {
                    //  width: 40%;
                }
            }

            .years-in-business {
                @media #{$medium-up} {
                    width: 70%;
                }
            }
        }
    }

    .business-type {
        color: $color-text-table-content;
        margin: 1.5em 2em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; // justify-content: space-around;

        .types {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 200px;
            font-size: 14px;

            .mat-mdc-form-field {
                margin: 0.5em 2em;
                // border: 1px solid #adadad;
                border-radius: 3px;
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-underline {
                    height: 0;
                }
            }

            label {
                color: $color-text-table-content;
                letter-spacing: 0.53px;
            }

            .mat-icon {
                font-size: 13px;
                height: 16px;
                width: 13px;
                color: $color-text-secondary;
                margin-left: 0.2em;
            }

            .mat-mdc-radio-group {
                display: flex;
                flex-direction: column;
            }
        }

        .movement-types-and-merchandise-condition-container {
            width: 100%;
            color: $ups-gray-1;

            .movement-types {
            }

            .merchandise-condition {
                margin-top: 22px;
            }

            .first {
                margin-top: 8px;
            }
            
            .percent-container {
                display: block;

                @media #{$medium-up} {
                    display: flex;
                    height: 48px;
                }


                span {
                    min-width: 150px;
                    height: 24px;
                }

                .percent-wrapper {
                    width: 100%;
                    display: flex;
                    height: 59px;
                    margin-left: 6px;

                    @media #{$medium-up} {
                        width: 20%;
                        margin-top: -5px;
                    }

                }
            }
        }
    }
}
