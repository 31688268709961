@import "config";
.reset-password {
    width: 100vw;
    height: 100vh; 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .reset-password-wrapper {        
        width: 100%;
        height: 100%;
        box-shadow: 0 2px 4px 0 rgba(162, 162, 162, 0.5);
        border: 1px solid $ups-background-color;

        @media #{$medium-up} {            
            width: 30em;
            margin-top: 4em;
            height: auto;
        }

        .page-content-items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2em;
            @media #{$medium-up} {
                padding: 2em 4em;
            }

            .logo {
                margin: .5em auto 2em;
            }

            h2 {
                font-size: 1.125em;
                font-weight: 600;
                letter-spacing: .5px;
            }

            .reset-form {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 2em;

                .valid-password {
                    margin: 0 .5em;
                }

                [class*="mat-form-field-infix"]:nth-child(1){                    
                    
                    .validation-message {                        
                        width: 15.5em;
                        background-color: $background-color-panel;
                        border: solid 1px $ups-background-color;
                        box-shadow: 0 2px 2px 0 rgba(197, 197, 197, 0.5);
                        color: #707070;
                        text-align: center;
                        border-radius: 6px;
                        padding: 5px;
                        position: absolute;
                        z-index: 1;
                        top: -8em;
                        left: 33%;

                        @media #{$medium-up} {
                            top: -3em;
                            left: 102%;
                        }                        

                        .heading{
                            font-weight: 600;                            
                            margin-left: -2em;
                        }

                        ul{
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }

                        ul li{
                            margin-left: -1em;
                            text-align: left;
                            //list-style-image: url('../../../assets/icons/invalid-value.png')
                        }

                        // ul li:nth-of-type(2n){
                        //     //list-style-image: url('../../../assets/icons/valid-value.png')
                        // }
                    }

                    .validation-message::before {
                        content: '';                        
                        position: absolute;                            
                        border: 11px solid transparent;                        
                        top: 101%;
                        right: 80%; 
                        border-color: $ups-background-color transparent transparent transparent ;
                        
                        @media #{$medium-up} {
                            top: 42%;
                            right: 100%;
                            border-color: transparent $ups-background-color transparent transparent;   
                        }
                    }

                    .validation-message::after {
                        content: "";
                        position: absolute;                        
                        margin-top: 0;
                        border-width: 9px;
                        border-style: solid;                        
                        top: 100%;
                        right: 80%;
                        border-color:#fff transparent transparent transparent;

                        @media #{$medium-up} {
                            margin-top: -5px;
                            top: 49%;
                            right: 100%;
                            border-color: transparent #fff;
                        }
                    }
                  
                }
            }

            .trouble {
                @extend .flex-center;
                text-align: center;
                width: 100%;
                font-size: .9em;
                color: $ups-bray;
                a {
                    font-weight: 600;
                    color: $ups-green;
                }
            } 

            .success{
                display: flex;
                margin-bottom: 1em;
                flex-direction: column;    
                padding: 1em;

                h2{
                    color:$ups-black-4;                    
                    line-height: 1.5em;    
                    text-align: center;
                    align-self: center;
                    margin-bottom: 3em;
                    font-size: 1.25em;
                    font-weight: normal;

                    @media #{$medium-up} {
                        width: 75%;
                    }
                }
            }
            
        }
    }   
}
