@import '../../assets/css/config.scss';

.sign-up {
    height: 100%;
    border-top: 3px solid $ups-gold;

    .header {
        padding-top: 1em;

        @media #{$medium-up} {
            padding: 1em 8em;
        }

        .ups-logo img {
            height: auto;
            width: 12em !important;

            @media #{$medium-up} {
                width: 17.8em;
            }
        }

        .header-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between; // margin: 0 auto;

            @media #{$medium-up} {
                flex-direction: row;
                align-items: stretch;
            }

            .header-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                font-size: 13px; // line-height: 38px;

                :first-child {
                    margin-bottom: 0.3em;

                    @media #{$medium-up} {
                        margin-bottom: 0em;
                    }
                }

                .login {
                    border-radius: 19.5px;
                    height: 39px;
                    width: 150px;
                }

                label {
                    margin-right: 2em;
                    font-weight: 600;
                }
            }
        }
    }

    .sign-up-wrapper {
        background-color: #f6f6f6;

        @media #{$medium-up} {
            padding: 0em 8em;
        }

        .account-registration {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $ups-bray;
            padding: 0.8em 1em;

            @media #{$medium-up} {
                flex-direction: row;
                align-items: flex-end;
                padding: 1.2em 0em;
            }

            .title {
                font-size: 28px;
                font-weight: 600;
                line-height: normal;
            }

            .help-desk {
                font-size: 14px;

                .phone {
                    font-weight: 600;
                }
            }
        }

        .page-content {
            background-color: $background-color-panel;
            border-radius: 3px;

            .required {
                color: $ups-warning-red;
                font-size: 13px;
            }

            .page-title {
                background-color: #ededed;
                padding-left: 1em;
                line-height: 52px;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0.70px;
                color: $color-text-table-content;
                height: 52px;
            }

            .mat-step-label {
                color: #595555;
                font-size: 20px;               
            }
            .mat-step-label-active {
                font-weight: bold;
            }

            .account-information {
                margin-bottom: 10px;

                .company-info {
                    margin: 1em 1em;
                    display: flex;
                    flex-direction: column;

                    @media #{$medium-up} {
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    .mat-mdc-form-field {
                        @media #{$medium-up} {
                            width: 48%;
                            padding: 0em 1em;
                        }
                    }
                }

                .contact-preference {
                    color: $color-text-table-content;
                    display: flex;
                    flex-direction: column;
                    margin-left: 2em;

                    >label {
                        font-size: 19px;
                        font-weight: 600;
                        letter-spacing: 0.66px;
                    }
                    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
                    .mat-radio-label-content {
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.46px;
                    }

                    .mat-mdc-radio-button {
                        margin-left: 3em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .separator {
                border-top: 1px solid #ededed;
                margin: 1em 1em;

                @media #{$medium-up} {
                    width: 93%;
                }
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                flex-wrap: wrap;

                @media #{$medium-up} {
                    margin-right: 3em;
                }

                button {
                    margin-right: 2em;

                    &:last-child {
                        margin-right: 0;
                        height: 40px;
                        border-radius: 19.5px;
                    }
                }
            }

            .captcha{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;                
                margin-bottom: 1em;

                @media #{$medium-up} {
                    margin-right: 3em;
                    
                }
            }

            .title {
                margin-top: 1em;
                font-size: 20px;
                font-weight: 600;
                width: 100%;
                letter-spacing: 0.66px;
                color: $color-text-table-content;

                @media #{$medium-up} {
                    margin: 0.5em 0 1em 1em;
                }
            }

            .subtitle {
                margin: 0 0 1.5em 1.4em;
                font-size: 14px;
                font-weight: 600;
                width: 100%;
                letter-spacing: 0.46px;
                color: $color-text-secondary;

                @media #{$small-only} {
                    margin-left: 0;
                }
            }

            .submitted {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @media #{$medium-up} {
                    min-height: 532px;
                }

                p {
                    font-size: 26px;
                    line-height: 1.54em;
                    margin: 1em 3em;
                    color: #595555;
                    text-align: center;                    
                }

                p:first-child {
                    font-size: 30px;                                        
                    font-weight: 600;
                }

                button {
                    margin-top: 2em;
                    border-radius: 19.5px;
                }
            }
            .mat-step-header:hover{
                background-color: transparent;
            }
        }

        .footer {
            font-size: 12px;
            color: #8c8585;
            padding: 2em 0em;
        }
    }

    .welcome {
        @include flex-center();
        font-size: 1.25rem;
        font-weight: 300;
        padding: .75em 0;
        letter-spacing: .025em;
        background-color: $ups-blue-ppi;
        color: white;
    }
}
